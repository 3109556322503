<template>
  <section>
    <pps-nav></pps-nav>
    <pps-header></pps-header>
    <pps-main></pps-main>
    <pps-footer></pps-footer>
  </section>
</template>

<script>
export default {
name:'pps-container'
}
</script>

<style scoped>
section {
  height: 1000px;
}
</style>